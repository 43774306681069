<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="text-gray-300">
          <div class="">
            <div class="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2
                  class="text-5xl font-extrabold sm:text-5xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                >
                  Privacy Policy
                </h2>
                <dd
                  class="mt-4 text-base text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200 text-xl"
                >
                  This Privacy Policy describes how PromptDue ("we", "us", or
                  "our") collects, uses, and discloses information about you in
                  connection with your use of our website (the "Site") and the
                  services we offer (the "Services").
                </dd>
              </div>
              <div class="col-span-2 mt-10 lg:mt-0">
                <dl class="space-y-10">
                  <div></div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Information We Collect
                    </dt>
                    <dd class="mt-2 text-base text-gray-00">
                      We collect personal information about you in various ways
                      when you use our Site and Services. This may include:

                      <br />
                      <br />
                      Personal information that you provide to us directly, such
                      as your name, email address, mailing address, phone
                      number, payment information, and other information that
                      you provide when you create an account, submit a prompt,
                      or contact us for support or other inquiries. Information
                      about your use of the Site and Services, including your IP
                      address, browser type, referring/exit pages, operating
                      system, date/time stamps, and related data. We may also
                      collect information about your usage patterns, such as the
                      pages you visit, the prompts you create, and the searches
                      you perform on our Site. Information about your
                      interactions with other users on our Site and Services,
                      including messages sent through our messaging system,
                      feedback ratings, and other user-generated content.

                      <br />
                      Information that we obtain from third-party sources, such
                      as social media platforms, marketing partners, and other
                      data providers.

                      <br />
                      Use of Information We use the information we collect from
                      you for a variety of purposes, including:
                      <br />Providing and improving the Site and Services,
                      including enabling you to create and share prompts,
                      facilitating payments, and providing support and other
                      services. Analyzing usage of the Site and Services,
                      monitoring and preventing fraud and abuse, and improving
                      the performance and security of our Site and Services.
                      Communicating with you about our Site, Services, and other
                      related topics. Marketing our Site and Services, including
                      sending you promotional communications about our products
                      and services that we think may interest you. Personalizing
                      your experience on our Site and Services, including
                      tailoring content and advertising to your interests and
                      preferences. Sharing of Information We may share your
                      information with third parties for the following purposes:
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Service Providers
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      We may share your information with third-party service
                      providers who perform services on our behalf, such as
                      payment processing, data analysis, and marketing. Users:
                      We may share information about you with other users of our
                      Site and Services, such as when you create a prompt or
                      engage in other user-to-user interactions on our Site.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Business Partners
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      We may share your information with our business partners,
                      such as advertisers and marketing partners, to provide you
                      with relevant content and offers. Legal and Compliance: We
                      may disclose your information to third parties when we
                      believe such disclosure is necessary to comply with
                      applicable laws and regulations, respond to legal process,
                      or protect our rights or the rights of others. Cookies and
                      Similar Technologies We may use cookies, web beacons, and
                      other similar technologies to collect information about
                      your usage of the Site and Services. These technologies
                      allow us to remember your preferences, customize content
                      and advertising, and monitor the performance of our Site
                      and Services. You can choose to disable cookies or adjust
                      your preferences through your web browser settings, but
                      doing so may limit your ability to use certain features of
                      our Site and Services.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Third-Party Websites and Services
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Our Site and Services may contain links to third-party
                      websites and services, which may have their own privacy
                      policies and practices. We are not responsible for the
                      privacy practices of these third-party websites and
                      services, and we encourage you to review their privacy
                      policies before providing them with any information.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Security
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      We take reasonable measures to protect the information we
                      collect from you against unauthorized access, use, and
                      disclosure. However, no method of transmission over the
                      internet or method of electronic storage is completely
                      secure, and we cannot guarantee the absolute security of
                      your information.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Children's Privacy
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Our Site and Services are not intended for use by children
                      under the age of 18, and we do not knowingly collect
                      personal information from children under the age of 18. If
                      we become aware that we have collected personal
                      information from a child under the age of 18, we will take
                      steps to delete that information as soon as possible.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Your Acceptance of These Terms
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      By using our Site and Services, you signify your
                      acceptance of this Privacy Policy. If you do not agree to
                      this policy, please do not use our Site. Your continued
                      use of the Site and Services following the posting of
                      changes to this policy will be deemed your acceptance of
                      those changes.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Contacting Us
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      If you have any questions about this Privacy Policy, the
                      practices of our Site and Services, or your dealings with
                      our Site and Services, please contact us at [insert
                      contact information]. Thank you for choosing PromptDue. We
                      value your privacy and will strive to protect your
                      personal information while providing you with the best
                      possible service.
                    </dd>
                  </div>

                  <h2
                    class="text-2xl font-extrabold sm:text-2xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                  >
                    Credit : ChateGPT
                  </h2>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StickFooter />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

export default {
  data() {
    return {
      products: [],
      products_2: [],
      prompts: [],
    };
  },
  components: { ConnectWallet, StickFooter },
  methods: {},
  created() {},
};
</script>
